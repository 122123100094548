import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  HostListener,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { StateStorageService } from 'app/core/auth/state-storage.service';
import { LoginService } from 'app/core/login/login.service';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogHelpComponent } from 'app/dialog/dialog-help/dialog-help.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogSettingComponent } from 'app/dialog/dialog-setting/dialog-setting.component';
import { Common } from 'app/model/entity/common';
import {
  ResetSortFilterLCDLayoutEditorStateAction,
  ResetSortFilterLEDLayoutEditorStateAction,
  ResetSortFilterStateAction,
  ResetSortFilterUserManagerStateAction,
  SaveMainStateAction
} from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { ExecutingService } from 'app/service/executing.service';
import { TimetableService } from 'app/service/timetable.service';
import { UserService } from 'app/service/user.service';
import { AppState } from 'app/store/app.state';
import { Subject, interval, timer } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AutoLogoutTime, Constant, FIELD_COMPONENT, TYPE_LAYOUT } from '../../config/constants';
import { LayoutComponent } from '../layout/layout.component';
import { Layout1Component } from '../layout1/layout1.component';
import { Layout2Component } from '../layout2/layout2.component';
import { Layout3Component } from '../layout3/layout3.component';
import { Layout4Component } from '../layout4/layout4.component';
import { Layout5Component } from '../layout5/layout5.component';
import { Layout6Component } from '../layout6/layout6.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
/**
 * Class MainComponent
 */
export class MainComponent implements OnInit {
  countRequests: number = 0;
  timeoutCount: number = 0;
  private timeout = -1;
  private readonly resetTimerSubject = new Subject();
  private readonly stopIntervalSubject = new Subject();
  Constant = Constant;
  typeLayout: TYPE_LAYOUT = TYPE_LAYOUT.TYPE;
  TYPE_LAYOUT = TYPE_LAYOUT;
  componentRef: ComponentRef<any>;
  @ViewChild('moduleLayout', {
    read: ViewContainerRef,
    static: true
  })
  moduleLayout: any;
  projectName: string = '';
  username: string = '';
  timeOutId: any;
  commonObject: Common;
  /**
   * current layout
   */
  private currentLayout: TYPE_LAYOUT;
  /**
   * list value software depend on project
   */
  private static readonly VALUES_FIELD_COMPONENT_DEPEND_ON_PROJECT: any = [
    FIELD_COMPONENT.MediaManagerComponent,
    FIELD_COMPONENT.RouteListEditorComponent,
    FIELD_COMPONENT.DestinationSignEditorComponent,
    FIELD_COMPONENT.SignageDisplayEditorComponent,
    FIELD_COMPONENT.BusInformationDisplayEditorComponent,
    FIELD_COMPONENT.StationDisplayEditorComponent,
    FIELD_COMPONENT.DigitalSignageContentEditorComponent
  ];
  /**
   * name field component property
   */
  private static readonly FIELD_COMPONENT_1 = 'fieldComponent1';
  private static readonly FIELD_COMPONENT_2 = 'fieldComponent2';
  private static readonly FIELD_COMPONENT_3 = 'fieldComponent3';
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private dataService: DataService,
    private dialogService: DialogService,
    private http: HttpClient,
    private injector: Injector,
    private executingService: ExecutingService,
    public readonly store: Store<AppState>,
    private loginService: LoginService,
    private stateStorageService: StateStorageService,
    private router: Router,
    private translateService: TranslateService,
    private userService: UserService,
    private timetableService: TimetableService,
    private commonService: CommonService
  ) {
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'userName') {
        this.username = <string>data[1] ?? '';
        // start counting for inactive time
        this.startTimer();
      }
    });
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'projectName') {
        this.projectName = <string>data[1] ?? '';
      }
    });
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'zoomOutScreen') {
        this.changeLayout(TYPE_LAYOUT.TYPE);
      }
    });
    this.executingService.executingRequest.subscribe(isLoadingData => {
      if (isLoadingData) {
        this.countRequests++;
      } else if (this.countRequests > 0) {
        this.countRequests--;
      }
    });
    this.dataService.resetLayoutAction.subscribe(isReset => {
      if (isReset) {
        this.resetComponentWhenDeleteProject();
      }
    });
    this.resetTimerSubject.subscribe(() => {
      if (this.timeoutCount >= 60) {
        this.timeoutCount = 0;
        this.http.get(Constant.BACKEND_URL + 'auth/inactive').toPromise();
      }
    });
    this.store
      .select(state => state)
      .subscribe((componentState: any) => {
        this.commonObject = componentState.mainState?.stateOfComponent.common ?? new Common();
        const setting = this.commonObject.setting;
        if (setting) {
          switch (setting.autoLogoutTime) {
            case AutoLogoutTime.FIVE_MINUTE:
              this.timeout = 5 * 60;
              break;
            case AutoLogoutTime.TEN_MINUTE:
              this.timeout = 10 * 60;
              break;
            case AutoLogoutTime.THIRSTY_MINUTE:
              this.timeout = 30 * 60;
              break;
            case AutoLogoutTime.ONE_HOUR:
              this.timeout = 60 * 60;
              break;
            case AutoLogoutTime.NEVER:
            default:
              break;
          }
        }
      });
  }

  @HostListener('window:click', ['$event'])
  onMouseClicked(event) {
    this.resetTimer();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPressed(event) {
    this.resetTimer();
  }

  @HostListener('window:mousewheel', ['$event'])
  onMouseScrolled(event) {
    this.resetTimer();
  }

  ngOnInit(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.getLayout(+sessionStorage.getItem(Constant.LAYOUT)));
    this.componentRef = this.moduleLayout.createComponent(factory);
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.store
      .select(state => state)
      .subscribe((componentState: any) => {
        this.commonObject = componentState?.mainState?.stateOfComponent?.common ?? new Common();
        delete this.commonObject.isLogout;
        delete this.commonObject.isLoadedFonts;
        if (Object.values(this.commonObject).every(object => !object)) {
          return;
        }
        this.commonObject.privileges = componentState?.userInfoState?.stateOfComponent.privileges;
        this.commonObject.user = componentState?.userInfoState?.stateOfComponent.user;
        sessionStorage.setItem(Constant.COMMON_INFORMATION, JSON.stringify(this.commonObject));
      });
    if (
      this.checkChangeDataWhenReload('componentRef') ||
      this.checkChangeDataWhenReload('componentRef2') ||
      this.checkChangeDataWhenReload('componentRef3')
    ) {
      var confirmationMessage = 'o/';
      (event || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
    this.userService.updateUserLogout(this.commonObject.userIdString).subscribe();
    return true;
  }

  /**
   * check change data when reload
   *
   * @param componentRefName
   * @returns
   */
  checkChangeDataWhenReload(componentRefName: string) {
    return (
      this.componentRef.instance[componentRefName]?.instance?.isChangedData ||
      this.componentRef.instance[componentRefName]?.instance?.isChangedNewsTab ||
      this.componentRef.instance[componentRefName]?.instance?.isChangedWeatherTab ||
      this.componentRef.instance[componentRefName]?.instance?.isChangeMediaOfSequence ||
      this.componentRef.instance[componentRefName]?.instance?.isShowFreeArea ||
      this.componentRef.instance[componentRefName]?.instance?.isChangeAudioOfSequence ||
      this.componentRef.instance[componentRefName]?.instance?.isChangeCommonRegistration ||
      this.componentRef.instance[componentRefName]?.instance?.isChangeSpecificRegistration
    );
  }

  /**
   * Change layout
   * @param typeLayout TYPE_LAYOUT
   */
  changeLayout(typeLayout: TYPE_LAYOUT) {
    this.currentLayout = typeLayout;
    sessionStorage.setItem(Constant.LAYOUT, `${typeLayout}`);
    switch (typeLayout) {
      case TYPE_LAYOUT.TYPE:
        this.createComponent(LayoutComponent);
        break;
      case TYPE_LAYOUT.TYPE1:
        this.createComponent(Layout1Component);
        break;
      case TYPE_LAYOUT.TYPE2:
        this.createComponent(Layout2Component);
        break;
      case TYPE_LAYOUT.TYPE3:
        this.createComponent(Layout3Component);
        break;
      case TYPE_LAYOUT.TYPE4:
        this.createComponent(Layout4Component);
        break;
      case TYPE_LAYOUT.TYPE5:
        this.createComponent(Layout5Component);
        break;
      case TYPE_LAYOUT.TYPE6:
        this.createComponent(Layout6Component);
        break;
      default:
        break;
    }
  }

  /**
   * reset component depend on project when delete project
   */
  private resetComponentWhenDeleteProject(): void {
    // set field component 1
    this.setFieldComponent(MainComponent.FIELD_COMPONENT_1);
    // set field component 2
    this.setFieldComponent(MainComponent.FIELD_COMPONENT_2);
    // set field component 3
    this.setFieldComponent(MainComponent.FIELD_COMPONENT_3);
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
    this.changeLayout(this.currentLayout);
  }

  /**
   * rest field component for common object
   * @param fieldComponentName
   * @returns
   */
  private setFieldComponent(fieldComponentName: string): void {
    if (!this.commonObject || !this.commonObject[fieldComponentName]) {
      return;
    }
    let fieldComponent = this.commonObject[fieldComponentName].split('-')[0];
    if (fieldComponent === undefined || !MainComponent.VALUES_FIELD_COMPONENT_DEPEND_ON_PROJECT.includes(+fieldComponent)) {
      return;
    }
    this.commonObject[fieldComponentName] = undefined;
  }

  createComponent(layout) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(layout);
    this.moduleLayout.clear();
    this.componentRef = this.moduleLayout.createComponent(factory, null, this.injector);
    this.changeDetectorRef.detectChanges();
    this.dataService.sendData([Constant.UPDATE_VIEW_BY_PRIVILEGES, null]);
  }

  /**
   * get layout
   * @param layout
   * @returns
   */
  private getLayout(layout: number): any {
    switch (layout) {
      case 0:
        return LayoutComponent;
      case 1:
        return Layout1Component;
      case 2:
        return Layout2Component;
      case 3:
        return Layout3Component;
      case 4:
        return Layout4Component;
      case 5:
        return Layout5Component;
      case 6:
        return Layout6Component;
      default:
        return LayoutComponent;
    }
  }

  /**
   * setting
   */
  setting() {
    this.dialogService.showDialog(DialogSettingComponent);
  }

  /**
   * handle logout
   */
  logout(): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('main.logout-dialog.title'),
          button1: this.translateService.instant('main.logout-dialog.yes'),
          button2: this.translateService.instant('main.logout-dialog.no')
        }
      },
      result => {
        if (result) {
          if (!this.commonObject?.userIdString) {
            this.showDialogErrorMessage();
            return;
          }
          // this.loginService.logout();
          this.userService.updateUserLogout(this.commonObject?.userIdString).subscribe(
            () => {
              this.goToLoginPage();
            },
            error => {
              this.showDialogErrorMessage(error);
            }
          );
        }
      }
    );
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  resetTimer() {
    this.resetTimerSubject.next();
  }

  startTimer() {
    interval(1000)
      .pipe(takeUntil(this.stopIntervalSubject))
      .subscribe(() => {
        this.timeoutCount++;
        if (this.timeout != -1 && this.timeoutCount > this.timeout) {
          this.userService.updateUserLogout(this.commonObject?.userIdString).subscribe();
          this.stopIntervalSubject.next();
          this.stopIntervalSubject.unsubscribe();
          this.resetTimerSubject.unsubscribe();
          alert('Session timed out. Reload to login again.');
          sessionStorage.clear();
          this.store.dispatch(
            new SaveMainStateAction({
              common: undefined
            })
          );
          this.stateStorageService.storeUrl(this.router.routerState.snapshot.url);
          this.loginService.logout();

          window.location.reload();
        }
      });
    this.resetTimerSubject
      .pipe(
        startWith(0),
        switchMap(() => timer(10000))
      )
      .subscribe(() => {
        this.timeoutCount = 0;
        this.http.get(Constant.BACKEND_URL + 'auth/inactive').toPromise();
      });
  }

  /**
   * go to login page
   */
  public goToLoginPage(): void {
    sessionStorage.clear();
    this.store.dispatch(
      new SaveMainStateAction({
        common: new Common(true, this.commonObject.setting)
      })
    );
    this.store.dispatch(new ResetSortFilterStateAction());
    this.store.dispatch(new ResetSortFilterUserManagerStateAction());
    this.store.dispatch(new ResetSortFilterLCDLayoutEditorStateAction());
    this.store.dispatch(new ResetSortFilterLEDLayoutEditorStateAction());
    this.changeLayout(TYPE_LAYOUT.TYPE);
    this.dataService.sendData(['userName', '']);
    this.dataService.sendData(['projectName', '']);
  }

  /**
   * show dialog error message default
   * @param error
   */
  private showDialogErrorMessage(error?: any): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text:
          error?.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : this.translateService.instant('dialog-error.msg')
      }
    });
  }

  /**
   * show manual
   */
  public showManual(): void {
    this.dialogService.showDialog(DialogHelpComponent, {
      data: {
        commonObject: this.commonObject
      }
    });
  }
}
